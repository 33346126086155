import { AllActionsTypes } from "../actions_all";
import { PopulationDistribution } from "../../../src/Dashboard/Protocol/types";

export interface DataState {
    population_feature_distributions?: Map<string, PopulationDistribution>
}

export const SET_POPULATION_FEATURE_DISTRIBUTIONS = 'SET_POPULATION_FEATURE_DISTRIBUTIONS';
export const CLEAR_POPULATION_FEATURE_DISTRIBUTIONS = 'CLEAR_POPULATION_FEATURE_DISTRIBUTION';

export interface setPopulationFeatureDistributionsAction {
    type: typeof SET_POPULATION_FEATURE_DISTRIBUTIONS,
    payload: Map<string, PopulationDistribution>
}

export interface clearPopulationFeatureDistributionsAction {
    type: typeof CLEAR_POPULATION_FEATURE_DISTRIBUTIONS
}

export type DataActionTypes =
    | setPopulationFeatureDistributionsAction
    | clearPopulationFeatureDistributionsAction
    | AllActionsTypes;
