import * as React from 'react';
import { useState, useEffect } from 'react';
import { useContent } from "../../Content/cms";
import { Stats } from "../../Commons/statistics";

// Import components
import Section, { SubSection } from "../../Commons/Section";
import DataFrame from '../../Commons/Components/DataFrame';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../Commons/MultipleComponents";
import { AreaChart, ResponsiveContainer, XAxis, YAxis, Area, ReferenceLine } from "recharts";

// Import CSS
import './feature_extraction.css';
import { PopUpError } from '../../Commons/Helpers';


/*************** Data renders *********************/

interface Histogram {x: number[], y: number[]}

export interface Population {
    name: string
    feature_name: string
    fill_color: string
    stroke_color: string
    histogram: Histogram
}

export interface FeatureExtractionResScoresData{
    name: string
    status: "pending" | "failed" | "ready"
    value?: number
}

interface AreaData {
    x: number,
    [population: string]: number
}

interface FeatureExtractionRowData {
    name: string
    status: "pending" | "failed" | "ready"
    value?: number
    distribution: AreaData[]
    populations: Population[]
}

export interface FeatureExtractionSummaryProps {
    loadingPopulations: boolean
    data: FeatureExtractionResScoresData[]
    populations: Population[]
    
}

/****************************************************/


export const FeatureExtractionSummary : React.FC<FeatureExtractionSummaryProps> = (props) => {

    let getContent = useContent();
    const mobiles = require('is-mobile');
    let mobile = mobiles();

    let [info, setInfo] = useState<string|undefined>();

    let [shownPopulations, setShownPopulations] = useState<{[population: string]: {shown: boolean, fill_color: string, stroke_color: string}}>({});

    let [tableData, setTableData] = useState<FeatureExtractionRowData[]>([]);


    const setShownPopulationStateFromPopulation = (populations: Population[]) => {
        let newShownState = props.populations.reduce((acc: {[population: string]: {shown: boolean, fill_color: string, stroke_color: string}}, population) => {
            acc[population.name] = {
                shown: true,
                fill_color: population.fill_color,
                stroke_color: population.stroke_color
            };
            return acc;
        }, {});

        setShownPopulations(newShownState);
    };

    useEffect(
        () => {

            setShownPopulationStateFromPopulation(props.populations);

            let newTableData : FeatureExtractionRowData[] = [];

            for (const value of Object.values(props.data)) {
        
                let distribution : AreaData[] = [];

                let populations = props.populations.filter(population => population.feature_name === value.name);
        
                if (value.status === 'ready') {

                    // add the population distributions
                    for (let population of populations) {
        
                        let y = 'y_' + population.name;
                        
                        // Add an initial value with value zero to smooth the curve.
                        // The value is added at the start of the valid range sent to not overpass the data x range
                        distribution.push({
                            x: population.histogram.x[0],
                            [y]: 0
                        });
                        
                        // Add the curve values using the center of the X bins as x points.
                        for (let i = 0; i < population.histogram.x.length-1; i++) {
                            distribution.push({
                                x: Stats.mean(population.histogram.x.slice(i,i+2)),
                                [y]: population.histogram.y[i]!
                            });
                        }
        
                        // Add a last value  zero to smooth the curve.
                        // The value is added at the end of the valid range sent to not overpass the data x range
                        distribution.push({
                            x: population.histogram.x[population.histogram.x.length-1],
                            y: 0
                        });
                    }
                }

                newTableData.push({
                    name: value.name,
                    status: value.status,
                    value: value.value,
                    distribution: distribution,
                    populations: populations
                });
            }

            setTableData(newTableData);
        }, [props.data, props.populations] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function toggleShownPopulation(population: string) {
        setShownPopulations(prev => {

            // if population is the only one shown, don't allow to hide it
            let shownPopulations = Object.entries(prev)
                .filter(([_, state]) => state.shown)
                .map(([population, _]) => population);
            if (shownPopulations.length === 1 && shownPopulations[0] === population) {
                PopUpError(getContent('protocol_result__feature_extraction__at_least_one_population_shown'));
                return prev;
            }

            let newShownPopulations = {...prev};
            newShownPopulations[population]['shown'] = !prev[population]['shown'];
            return newShownPopulations;
        });
    }


    const header_content = mobile === false ? [
        getContent("protocol_result__feature_extraction__sumary_table__header__metric"),
        getContent("protocol_result__feature_extraction__sumary_table__header__current_value"),
        getContent("protocol_result__feature_extraction__sumary_table__header__population_distribution"),
        getContent("protocol_result__feature_extraction__sumary_table__header__description"),
        ''
    ] : [
        getContent("protocol_result__feature_extraction__sumary_table__header__metric"),
        getContent("protocol_result__feature_extraction__sumary_table__header__current_value"),
        ''
    ];
    
    const header = header_content.map(col => <p className={'font-weight-bold text-center'}>{col}</p>);

    const sub_header = mobile === false ? [
        <Row className='text-center bg-primary-light w-100 p-0'>
            <p className='small-text pb-0 poblation-title'> <b>Poblaciones:</b> </p>
                {Object.entries(shownPopulations).map(([population, state]) => {
                    return <div key={'toggle_population_' + population} className='form-control-lg custom-control custom-checkbox pb-0'>
                        <input
                            type={"checkbox"}
                            checked={state.shown}
                            onChange={() => toggleShownPopulation(population)}
                            id={(population + '_checkbox')}
                            className='custom-control-input'
                        />
                        <label className='custom-control-label small-text' htmlFor={(population + '_checkbox')}>{population}</label>
                    </div>
                })}

                {props.loadingPopulations && <Spinner animation={'border'} size={'sm'} className='mt-3' />}

            </Row>
    ] : undefined;

    let fullSerie = [];

    for (const value of tableData) {

        let value_str = null;
        let distribution = null;

        if (value.status === 'pending') {

            value_str = <div className={'text-center'}><Spinner animation={'border'} size={'sm'} /></div>;
            distribution = <div className={'text-center'}><Spinner animation={'border'} size={'sm'} /></div>;
        
        } else if (value.status === 'failed') {

            value_str = <Alert variant={'danger'}>{getContent('protocol_result__feature_extraction__error')}</Alert>;
            distribution = <Alert variant={'danger'}>{getContent('protocol_result__feature_extraction__error')}</Alert>;
        
        } else if (value.status === 'ready') {

            if (value.value !== undefined) {
                
            }

            if (value.value === undefined) {
                throw new Error('value_str is undefined');
            } else {

                if (value.value >= 0.01){
                    value_str = value.value.toFixed(2);
                } else {
                    value_str = value.value.toExponential(2);
                }

                value_str = (
                    <p className={'text-center'}>{value_str}</p>
                );
            }

            distribution = (
                <ResponsiveContainer width={'100%'} height={100}>
                    <AreaChart data={value.distribution}>
                        <XAxis
                            dataKey={'x'}
                            domain={['dataMin', 'dataMax']}
                            type={'number'}
                            tickFormatter={(x) => x.toFixed(2)}
                            interval={'preserveStartEnd'}
                            tick={{fontSize: 12}}
                            label={{
                                value: '',
                                position:'insideBottomRight',
                                offset: 0
                            }}
                        />

                        <YAxis type={'number'} hide />

                        {value.populations
                            .filter(population => shownPopulations[population.name]?.shown ?? false)
                            .map((population, idx) => {
                                return <Area
                                    key={'area_' + population.name + '_' + idx.toString()}
                                    type={"monotone"}
                                    dataKey={"y_" + population.name}
                                    stroke={population.stroke_color}
                                    fill={population.fill_color}
                                    strokeWidth={0.3}
                                />
                            })
                        }

                        <ReferenceLine
                            x={value.value}
                            stroke={'#ba1c1a'}
                            strokeWidth={1}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            );
        }

        fullSerie.push(mobile === false ? [
            <p className={'mt-2'}>{getContent("protocol_result__feature_extraction__" + value.name + "__name")}</p>,
            value_str,
            distribution,
            <small className="d-inline-block ">{getContent("protocol_result__feature_extraction__" + value.name + "__short_explanation")}</small>,
            <IconButton
                text={getContent('protocol_result__feature_extraction__show_more_metric_info')}
                icon={{icon:faInfo, color:"#5CB6E7"}}
                onClick={() => setInfo(value.name)}
                className={'text-center w-100'}
            />
        ] : [
            <p className={'mt-2'}>{getContent("protocol_result__feature_extraction__" + value.name + "__name")}</p>,
            value_str,
            <IconButton
                text={getContent('protocol_result__feature_extraction__show_more_metric_info')}
                icon={{icon:faInfo, color:"#5CB6E7"}}
                onClick={() => setInfo(value.name)}
                className={'text-center w-100'}
            />
        ]);
    }

    return (
        <Section
            title={getContent("protocol_result__feature_extraction__title")}
            className={'secondary-section'}
            headerClassName={'secondary-section-header'}
            bodyClassName={'secondary-section-body'}
        >
            <SubSection title={''} className={'secondary-subsection '}>
                <DataFrame
                    header={header}
                    subheader={sub_header}
                    dataframe={fullSerie}
                    striped={false}
                    className={'header-bordered mt-3'}
                    columnClassName={'text-center'}
                    pageSize={fullSerie.length+1}
                    columnWidths={[20,10,30,30,10]}
                />
            </SubSection>
            <Modal show={info !== undefined} onHide={() => setInfo(undefined)} size="lg">
                <Modal.Header closeButton >
                    <Modal.Title>
                        {info !== undefined && getContent("protocol_result__feature_extraction__" + info + "__name")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>{getContent('protocol_result__measure_explanation__title__brief')}</b></p>
                    <p >{ info !== undefined && getContent("protocol_result__feature_extraction__" + info + "__short_explanation")} </p>
                    <p><b>{getContent('protocol_result__measure_explanation__title__long')}</b></p>
                    {info !== undefined && <div dangerouslySetInnerHTML={{__html: getContent("protocol_result__feature_extraction__" + info + "__large_explanation") as string}}/>}
                    <p><b>{getContent('protocol_result__measure_explanation__title__references')}</b></p>
                    {info !== undefined &&
                    <div dangerouslySetInnerHTML={{__html: getContent("protocol_result__feature_extraction__" + info + "__references") as string}}/>}
                </Modal.Body>
            </Modal>
        </Section>
    );
};
